const initialState = {
    layoutInverted: false,
}

export default function appReducer(state = initialState, action)
{
    switch(action.type)
    {
        case "layout/inverse":
            return{
                ...state,
                layoutInverted: action.payload,
            }
        default:
            return state;
    }
}