// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import store from 'Store/store';
import {useSelector} from "react-redux";


function JobReponsibilities({ responsibilities }) {
  const listOfresponsibilities = [];

  for (const item of responsibilities) {
    listOfresponsibilities.push(
      <Stack>
        <MKBox display="flex" alignItems="center" p={2}>
          <MKBox
            width="1rem"
            height="1rem"
            variant="gradient"
            bgColor="info"
            color="white"
            coloredShadow="info"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="xl"
          >
            <Icon fontSize="small">radio_button_checked</Icon>
          </MKBox>
          <MKTypography variant="body2" color="text" pl={2}>
            {item}
          </MKTypography>
        </MKBox>
      </Stack>
    );
  }
  return listOfresponsibilities;
}

function EmployerNameAndRole({ employer, range, title }) {
  return (
    <Grid item>
      <MKTypography variant="h3" my={1}>
        {employer}
      </MKTypography>
      <MKTypography variant="body2" color="text" mb={2}>
        {title} <br />
        {range}
      </MKTypography>
    </Grid>
  );
}

function JobWithDescription({ job }) {
  console.log(job.responsibilities);
    const layoutInverted = useSelector(state =>
    {
        if(state.layoutInverted)
            return job.alternate;
        else
            return !job.alternate;
    });


    return (
    <MKBox component="section" py={{ xs: 3, md: 6 }}>
      <Container>
        <Grid container alignItems="center">
          {layoutInverted? (
            <>
              <Grid
                container
                xs={12}
                lg={6}
                alignItems="center"
                justifyContent="center"
              >
                <EmployerNameAndRole
                  employer={job.employer}
                  range={job.range}
                  title={job.title}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}
              >
                <JobReponsibilities responsibilities={job.responsibilities} />
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={12}
                lg={6}
                sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}
              >
                <JobReponsibilities responsibilities={job.responsibilities} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                lg={6}
                alignItems="center"
                justifyContent="center"
              >
                <EmployerNameAndRole
                  employer={job.employer}
                  range={job.range}
                  title={job.title}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default JobWithDescription;
