import {createStore} from "@reduxjs/toolkit";
import appReducer from './reducer';

/* eslint-disable no-underscore-dangle */
const store = createStore(
    appReducer, /* preloadedState, */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */

export default store;

