/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Home page sections
import Counters from "./sections/Counters";
import JobWithDescription from "./components/JobWithDescription";
import Education from "./components/Education";
import Download from "./sections/Download";

// third party imports
import { Element } from 'react-scroll';

// Home page components
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/me-mysef-and-i2.jpeg";

function Home() {
  const educationDetails = {
    school: "University of Houston - Downtown",
    year: "2020",
    type: "Bachelor's",
    degree: "Computer Science",
  };
    const job0 = {
        employer: "Woodforest National Bank",
        title: "SDE II",
        range: "September 2023 - present",
        type: "Fulltime",
        responsibilities: [
            "Enhance and modify the existing .NET web API that interacts with other third party/in-house applications.",
            "Work with Azure DevOps pipelines and understand YAML files to ensure pipelines run smoothly.",
            "Reach out to business owners to understand PBIs and gather context/insight.",
            "Work with stored procedures and write TSQL queries to understand the manipulation of data."
        ],
        alternate: false,
    };
  const job1 = {
    employer: "Halliburton",
    title: "FullStack .NET Developer",
    range: "September 2021 - May 2023",
    type: "Contract",
    responsibilities: [
      "Learn Angular on-the-job and in spare time, including reactive components, RxJS to manage state, NGZorro (Ant Design) to manage UI/UX enhancements.",
      "Create API endpoints in C# using .NET 5, LINQ, SOLID principles, dependency injection, MEF, and problem-solving skills to parse through preexisting code.",
      "Write unit tests utilizing MSTest and MOQ to ensure quality code and results.",
      "Utilize Azure Devops to prioritize tasks/unblock other developers and keep track of user stories.",
    ],
    alternate: true,
  };
  const job2 = {
    employer: "Aramark",
    title: "FullStack .NET Developer",
    range: "Jun 2021 – Sep 2021",
    type: "Contract",
    responsibilities: [
      "Built and enhance a web application (.NET Framework C#) for Clinical Dieticians to track patient experiences.",
      "Consulted with business owners to revise and define requirements for current tickets/updates to web application.",
      "Gained familiarity with Kendo UI MVC, modifying and adding controls to UI of web application.",
      "Utilized Azure Devops for version control and sprints/tickets/agile practices.",
      "Worked with interfaces to implement dependency injection and keep SOLID standards",
    ],
    alternate: false,
  };
  const job3 = {
    employer: "AECOM",
    title: "FullStack .NET Developer",
    range: "Feb 2021 - May 2021",
    type: "Contract",
    responsibilities: [
      "Responsible for providing Tableau dashboards to TxDOT business owners that display data pertaining to the 25 districts in Texas, as well as creating the services to obtain that data.",
      "Created Windows services to ping servers and cell routers, utilizing the Twilio and SendGrid APIs to send text messages and emails to subscribed users when a device fails, using SQL with LINQ and ADO.NET.",
      "Write unit tests utilizing MSTest and MOQ to ensure quality code and results.",
      "Built a .NET Core MVC web app from the ground up to display the status of servers (online/offline) to users, as well as give users the ability to subscribe themselves to be notified if a server goes offline.",
    ],
    alternate: true,
  };
  const job4 = {
    employer: "Blue Sky Evolution",
    title: "FullStack .NET Developer",
    range: "Feb 2021 - May 2021",
    type: "Contract",
    responsibilities: [
      "Responsible for providing Tableau dashboards to TxDOT business owners that display data pertaining to the 25 districts in Texas, as well as creating the services to obtain that data.",
      "Created Windows services to ping servers and cell routers, utilizing the Twilio and SendGrid APIs to send text messages and emails to subscribed users when a device fails, using SQL with LINQ and ADO.NET.",
      "Write unit tests utilizing MSTest and MOQ to ensure quality code and results.",
      "Built a .NET Core MVC web app from the ground up to display the status of servers (online/offline) to users, as well as give users the ability to subscribe themselves to be notified if a server goes offline.",
    ],
    alternate: false,
  };
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://meetalexanthony.s3.amazonaws.com/AlexAnthonyResume.docx",
          label: "Download a Resume",
          color: "info",
        }}
        sticky
      />
      <MKBox
        width="100%"
        minHeight="402px"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "1584px 402px",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={9} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Alex Anthony, FullStack Developer{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              I'm a .NET C# Fullstack Developer with experience in Angular, but as you can see, wanting to branch out into React. This website is interactive to show that I know I thing or two about a thing or two.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Grid container justifyContent="center" name="Experience" lg={12} xs={12} sm={12}>
          <Grid item>
              <Element name="Experience">
            <MKTypography variant="h1">Experience</MKTypography>
              </Element>
          </Grid>
        </Grid>
        <JobWithDescription job={job0} />
        <JobWithDescription job={job1} />
        <JobWithDescription job={job2} />
        <JobWithDescription job={job3} />
        <Grid container justifyContent="center" name="Education"  lg={12} xs={12} sm={12}>
          <Grid item>
            <MKTypography variant="h1">Education</MKTypography>
          </Grid>
        </Grid>
        <Education educationDetails={educationDetails} />
        <Download />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
