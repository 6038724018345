// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";
import {LinkedIn} from "@mui/icons-material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Meet Alex Anthony",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <GitHubIcon />,
      link: "https://github.com/AVmachine",
    },
    {
      icon: <LinkedIn />,
      link: "https://linkedin.com/in/alexmanthony",
    }
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">&copy; {date} Made with love by {" "}
      <MKTypography
        component="a"
        href="https://www.meetalexanthony.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Alex
      </MKTypography>
      .
    </MKTypography>
  ),
};
