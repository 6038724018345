// @mui material components
// Material Kit 2 React components
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../../components/MKTypography";

import UHDLogo from "../../../../assets/images/UHD_logo.png";

function Education({ educationDetails }) {
  console.log(educationDetails);

  return (
    <MKBox component="section" py={{ xs: 3, md: 6 }}>
      <Grid container xs={12} lg={12}>
        <Grid container xs={12} lg={6} justifyContent="center">
          <Grid item>
            <img width={250} src={UHDLogo} alt="UHD Logo" />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKTypography variant="h2" color="text" pl={2}>
            {educationDetails.school}
          </MKTypography>
          <MKTypography variant="h3" color="text" pl={2}>
            {educationDetails.type} of {educationDetails.degree}
          </MKTypography>
          <MKTypography variant="h3" color="text" pl={2}>
            {educationDetails.year}
          </MKTypography>
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Education;
